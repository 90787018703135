
          
          
          import { CF2ComponentSingleton } from 'javascript/lander/runtime'

  class CheckoutNumberFormatterV1 extends CF2ComponentSingleton{
    numberToCurrency(number, currency) {
      if (currency && currency === '$' && Number.isFinite(number)) {
        return new Intl.NumberFormat('en-US', {style:"currency", currency:"USD", maximumFractionDigits: 2}).format(number).replace('$', '');
      } else if (currency && currency === '€' && Number.isFinite(number)) {
        return new Intl.NumberFormat('de-DE', {style:"currency", currency:"EUR", maximumFractionDigits: 2}).format(number).replace('€', '');
      }

      return number && number.toFixed(2)
    }
  }

  const numberFormatter = CheckoutNumberFormatterV1.getInstance();
  export default numberFormatter;
        